import React, { Component } from 'react';
import EntryGraphic from './EntryGraphic';
// import AnimBackground from './AnimBackground'
import "./Entry.css"

class Entry extends Component {
    render() {
        return (
            <div className="entryParent">
                {/* <Background /> */}
                <EntryGraphic/>
                {/* <AnimBackground/> */}
            </div>
        );
    }
}

export default Entry;