import React, { Component } from 'react';
import Modal from './Modal';
import "./EntryGraphic.css"

class EntryGraphic extends Component {
    state = {
        show: false
      };
      showModal = e => {
        this.setState({
            show: !this.state.show
        });
      }; 
      
    render() {
        
        return (
            <div className='full'>
                
                <svg width="100%"  viewBox="0 0 1041 356" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_10_13" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="126" y="30" width="144" height="125">
<path d="M198 30L270 155C229.226 117.751 166.774 117.751 126 155L198 30Z" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_10_13)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M199 31L199 128H197L197 31H199Z" fill="#9F9999" className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M213.001 48.9937L205.496 128.094L203.504 127.906L211.01 48.8048L213.001 48.9937Z" fill="#9F9999" className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M182.999 50.0945L190.504 129.195L192.496 129.006L184.99 49.9056L182.999 50.0945Z" fill="#9F9999" className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M222.001 73.9928L211.482 128.689L209.518 128.311L220.037 73.6151L222.001 73.9928Z" fill="#9F9999" className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M173.999 75.0936L184.518 129.79L186.482 129.412L175.963 74.7158L173.999 75.0936Z" fill="#9F9999" className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M232.007 85.9793L218.455 129.795L216.545 129.205L230.096 85.3883L232.007 85.9793Z" fill="#9F9999" className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M163.993 87.08L177.545 130.896L179.455 130.305L165.904 86.4891L163.993 87.08Z" fill="#9F9999" className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M240.001 98.997L224.406 132.423L222.594 131.577L238.189 98.1514L240.001 98.997Z" fill="#9F9999" className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M155.999 100.098L171.594 133.524L173.406 132.678L157.811 99.2522L155.999 100.098Z" fill="#9F9999" className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M247 110L230 135L228.346 133.875L245.346 108.875L247 110Z" fill="#9F9999"className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M149 111.101L166 136.101L167.654 134.976L150.654 109.976L149 111.101Z" fill="#9F9999"className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M252 118L235 139L233.445 137.742L250.445 116.742L252 118Z" fill="#9F9999"className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M144 119.101L161 140.101L162.555 138.842L145.555 117.842L144 119.101Z" fill="#9F9999"className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M257 127L241 143L239.586 141.586L255.586 125.586L257 127Z" fill="#9F9999"className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M139 128.101L155 144.101L156.414 142.687L140.414 126.687L139 128.101Z" fill="#9F9999"className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M261 137L246 147L244.891 145.336L259.891 135.336L261 137Z" fill="#9F9999"className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M135 138.101L150 148.101L151.109 146.437L136.109 136.437L135 138.101Z" fill="#9F9999"className='element'/>
</g>

{/* eye */}
<path d="M197.5 221C218.763 221 236 203.763 236 182.5C236 174.32 233.449 166.736 229.099 160.5L197 156L165.901 160.5C161.551 166.736 159 174.32 159 182.5C159 203.763 176.237 221 197.5 221Z" fill="#4E4E4E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M197.5 220C218.211 220 235 203.211 235 182.5C235 174.687 232.612 167.434 228.525 161.429L197.002 157.01L166.476 161.427C162.389 167.433 160 174.686 160 182.5C160 203.211 176.789 220 197.5 220ZM165.901 160.5L197 156L229.099 160.5C233.449 166.736 236 174.32 236 182.5C236 203.763 218.763 221 197.5 221C176.237 221 159 203.763 159 182.5C159 174.32 161.551 166.736 165.901 160.5Z" fill="#4E4E4E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M275.451 195.265C235.222 145.984 159.46 145.755 119.557 195.255L116.443 192.745C157.963 141.239 236.736 141.514 278.549 192.735L275.451 195.265Z" fill="#4E4E4E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M275.548 190.125C233.212 234.842 161.492 235.091 119.461 190.134L116.539 192.866C160.167 239.531 234.556 239.241 278.452 192.875L275.548 190.125Z" fill="#4E4E4E"/>
<path d="M179 182C179 192.493 187.507 201 198 201C203.214 201 207.937 198.9 211.37 195.5C196.962 196.553 184.993 184.541 186.096 170.137L186.337 167C181.872 170.477 179 175.903 179 182Z" fill="#D9D9D9"/>


<mask id="mask1_10_13" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="68" y="199" width="260" height="63">
<path d="M104.5 199.5C147.361 269.178 248.639 269.178 291.5 199.5L327.5 262H68.5L104.5 199.5Z" fill="#DB5E04"/>
</mask>
<g mask="url(#mask1_10_13)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M196 262V251H198V262H196Z" fill="#9F9999"className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M189 262V251H191V262H189Z" fill="#9F9999"className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M206 262V251H204V262H206Z" fill="#9F9999"className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M181 263L183 248L184.982 248.264L182.982 263.264L181 263Z" fill="#9F9999"className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M214 263L212 248L210.018 248.264L212.018 263.264L214 263Z" fill="#9F9999"className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M171 264L177 246L178.897 246.632L172.897 264.632L171 264Z" fill="#9F9999"className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M224 264L218 246L216.103 246.632L222.103 264.632L224 264Z" fill="#9F9999"className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M162 263L171 243L172.824 243.821L163.824 263.821L162 263Z" fill="#9F9999"className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M233 263L224 243L222.176 243.821L231.176 263.821L233 263Z" fill="#9F9999"className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M152 264L165 240L166.759 240.953L153.759 264.953L152 264Z" fill="#9F9999"className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M243 264L230 240L228.241 240.953L241.241 264.953L243 264Z" fill="#9F9999"className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M141 264L159 238L160.644 239.138L142.644 265.138L141 264Z" fill="#9F9999"className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M254 264L236 238L234.356 239.138L252.356 265.138L254 264Z" fill="#9F9999"className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M127 264L150 237L151.522 238.297L128.522 265.297L127 264Z" fill="#9F9999"className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M268 264L245 237L243.478 238.297L266.478 265.297L268 264Z" fill="#9F9999"className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M112 263L144 233L145.368 234.459L113.368 264.459L112 263Z" fill="#9F9999"className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M283 263L251 233L249.632 234.459L281.632 264.459L283 263Z" fill="#9F9999"className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M92 263L138 229L139.189 230.608L93.1888 264.608L92 263Z" fill="#9F9999"className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M303 263L257 229L255.811 230.608L301.811 264.608L303 263Z" fill="#9F9999"className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M66 262L133 222L134.025 223.717L67.0252 263.717L66 262Z" fill="#9F9999"className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M329 262L262 222L260.975 223.717L327.975 263.717L329 262Z" fill="#9F9999"className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M73 242L124 218L124.852 219.81L73.8516 243.81L73 242Z" fill="#9F9999"className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M322 242L271 218L270.148 219.81L321.148 243.81L322 242Z" fill="#9F9999"className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M79 226L124 209L124.707 210.871L79.7068 227.871L79 226Z" fill="#9F9999"className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M316 226L271 209L270.293 210.871L315.293 227.871L316 226Z" fill="#9F9999"className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M90 210L116 203L116.52 204.931L90.52 211.931L90 210Z" fill="#9F9999"className='element'/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M305 210L279 203L278.48 204.931L304.48 211.931L305 210Z" fill="#9F9999"className='element'/>
</g>
{/* left triangle */}
<path fill-rule="evenodd" clip-rule="evenodd" d="M198 18L51.6417 271.5H344.358L198 18ZM198 38L68.9622 261.5H327.038L198 38Z" fill="#4E4E4E"/>

{/* right triangle */}
<path d="M844 338L990.358 84.5H697.642L844 338Z" fill="#AFC8A9" fillOpacity={.5}/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M844 338L697.642 84.5H990.358L844 338ZM844 318L714.962 94.5H973.038L844 318Z" fill="#4E4E4E"/>


{/* Squares */}
<path className="square1" fill-rule="evenodd" clip-rule="evenodd" d="M411 85V232.889L480 294V146.111L411 85ZM414 91.6645V231.538L477 287.336V147.462L414 91.6645Z" fill="#C7C7C7" fillOpacity={.5}/>


<path className="square1" fill-rule="evenodd" clip-rule="evenodd" d="M460.5 81.6678L532.5 145.436V297.444L478.076 246.821L480.119 244.624L529.5 290.556V146.786L463.5 88.3322V131.444H460.5V81.6678Z" fill="#C7C7C7" fillOpacity={.5}/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M461 131H463V229.645L480.685 246.271L479.315 247.729L461 230.509V131Z" fill="#D9D9D9" fillOpacity={.2}/>


<path className="square1" fill-rule="evenodd" clip-rule="evenodd" d="M512.5 81.6678L584.5 145.436V297.444L530.076 246.821L532.119 244.624L581.5 290.556V146.786L515.5 88.3322V131.444H512.5V81.6678Z" fill="#C7C7C7" fillOpacity={.5}/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M513 131H515V229.645L532.685 246.271L531.315 247.729L513 230.509V131Z" fill="#D9D9D9" fillOpacity={.2}/>

<path className="square1" fill-rule="evenodd" clip-rule="evenodd" d="M564.5 81.6678L636.5 145.436V297.444L582.076 246.821L584.119 244.624L633.5 290.556V146.786L567.5 88.3322V131.444H564.5V81.6678Z" fill="#C7C7C7" fillOpacity={.5}/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M565 132H567V229.788L584.682 246.268L583.318 247.732L565 230.658V132Z" fill="#D9D9D9" fillOpacity={.2}/>

{/* circuit board  */}
<g >
<path className="circuit" fill-rule="evenodd" clip-rule="evenodd" d="M826.947 209.895C827.761 209.895 828.421 210.555 828.421 211.368L828.421 220.211C828.421 221.024 827.761 221.684 826.947 221.684C826.133 221.684 825.474 221.024 825.474 220.211L825.474 211.368C825.474 210.555 826.133 209.895 826.947 209.895Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M826.947 148.737C827.761 148.737 828.421 149.397 828.421 150.211L828.421 159.053C828.421 159.867 827.761 160.526 826.947 160.526C826.133 160.526 825.474 159.867 825.474 159.053L825.474 150.211C825.474 149.397 826.133 148.737 826.947 148.737Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M879.263 167.158C879.263 167.972 878.603 168.632 877.789 168.632L868.947 168.632C868.133 168.632 867.474 167.972 867.474 167.158C867.474 166.344 868.133 165.684 868.947 165.684L877.789 165.684C878.603 165.684 879.263 166.344 879.263 167.158Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M821.789 167.158C821.789 167.972 821.13 168.632 820.316 168.632L811.474 168.632C810.66 168.632 810 167.972 810 167.158C810 166.344 810.66 165.684 811.474 165.684L820.316 165.684C821.13 165.684 821.789 166.344 821.789 167.158Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M832.842 148.737C833.656 148.737 834.316 149.397 834.316 150.211L834.316 159.053C834.316 159.867 833.656 160.526 832.842 160.526C832.028 160.526 831.368 159.867 831.368 159.053L831.368 150.211C831.368 149.397 832.028 148.737 832.842 148.737Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M832.842 209.895C833.656 209.895 834.316 210.555 834.316 211.368L834.316 220.211C834.316 221.024 833.656 221.684 832.842 221.684C832.028 221.684 831.368 221.024 831.368 220.211L831.368 211.368C831.368 210.555 832.028 209.895 832.842 209.895Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M879.263 173.053C879.263 173.867 878.603 174.526 877.789 174.526L868.947 174.526C868.133 174.526 867.474 173.867 867.474 173.053C867.474 172.239 868.133 171.579 868.947 171.579L877.789 171.579C878.603 171.579 879.263 172.239 879.263 173.053Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M821.789 173.053C821.789 173.867 821.13 174.526 820.316 174.526L811.474 174.526C810.66 174.526 810 173.867 810 173.053C810 172.239 810.66 171.579 811.474 171.579L820.316 171.579C821.13 171.579 821.789 172.239 821.789 173.053Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M838.737 148.737C839.551 148.737 840.211 149.397 840.211 150.211L840.211 159.053C840.211 159.867 839.551 160.526 838.737 160.526C837.923 160.526 837.263 159.867 837.263 159.053L837.263 150.211C837.263 149.397 837.923 148.737 838.737 148.737Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M838.737 209.895C839.551 209.895 840.211 210.555 840.211 211.368L840.211 220.211C840.211 221.024 839.551 221.684 838.737 221.684C837.923 221.684 837.263 221.024 837.263 220.211L837.263 211.368C837.263 210.555 837.923 209.895 838.737 209.895Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M879.263 178.947C879.263 179.761 878.603 180.421 877.789 180.421L868.947 180.421C868.133 180.421 867.474 179.761 867.474 178.947C867.474 178.133 868.133 177.474 868.947 177.474L877.789 177.474C878.603 177.474 879.263 178.133 879.263 178.947Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M821.789 178.947C821.789 179.761 821.13 180.421 820.316 180.421L811.474 180.421C810.66 180.421 810 179.761 810 178.947C810 178.133 810.66 177.474 811.474 177.474L820.316 177.474C821.13 177.474 821.789 178.133 821.789 178.947Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M844.632 148.737C845.445 148.737 846.105 149.397 846.105 150.211L846.105 159.053C846.105 159.867 845.445 160.526 844.632 160.526C843.818 160.526 843.158 159.867 843.158 159.053L843.158 150.211C843.158 149.397 843.818 148.737 844.632 148.737Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M844.632 209.895C845.445 209.895 846.105 210.555 846.105 211.368L846.105 220.211C846.105 221.024 845.445 221.684 844.632 221.684C843.818 221.684 843.158 221.024 843.158 220.211L843.158 211.368C843.158 210.555 843.818 209.895 844.632 209.895Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M879.263 184.842C879.263 185.656 878.603 186.316 877.789 186.316L868.947 186.316C868.133 186.316 867.474 185.656 867.474 184.842C867.474 184.028 868.133 183.368 868.947 183.368L877.789 183.368C878.603 183.368 879.263 184.028 879.263 184.842Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M850.526 148.737C851.34 148.737 852 149.397 852 150.211L852 159.053C852 159.867 851.34 160.526 850.526 160.526C849.712 160.526 849.053 159.867 849.053 159.053L849.053 150.211C849.053 149.397 849.712 148.737 850.526 148.737Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M850.526 209.895C851.34 209.895 852 210.555 852 211.368L852 220.211C852 221.024 851.34 221.684 850.526 221.684C849.712 221.684 849.053 221.024 849.053 220.211L849.053 211.368C849.053 210.555 849.712 209.895 850.526 209.895Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M879.263 190.737C879.263 191.551 878.603 192.211 877.789 192.211L868.947 192.211C868.133 192.211 867.474 191.551 867.474 190.737C867.474 189.923 868.133 189.263 868.947 189.263L877.789 189.263C878.603 189.263 879.263 189.923 879.263 190.737Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M821.789 190.737C821.789 191.551 821.13 192.211 820.316 192.211L811.474 192.211C810.66 192.211 810 191.551 810 190.737C810 189.923 810.66 189.263 811.474 189.263L820.316 189.263C821.13 189.263 821.789 189.923 821.789 190.737Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M856.421 148.737C857.235 148.737 857.895 149.397 857.895 150.211L857.895 159.053C857.895 159.867 857.235 160.526 856.421 160.526C855.607 160.526 854.947 159.867 854.947 159.053L854.947 150.211C854.947 149.397 855.607 148.737 856.421 148.737Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M856.421 209.895C857.235 209.895 857.895 210.555 857.895 211.368L857.895 220.211C857.895 221.024 857.235 221.684 856.421 221.684C855.607 221.684 854.947 221.024 854.947 220.211L854.947 211.368C854.947 210.555 855.607 209.895 856.421 209.895Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M879.263 196.632C879.263 197.445 878.603 198.105 877.789 198.105L868.947 198.105C868.133 198.105 867.474 197.445 867.474 196.632C867.474 195.818 868.133 195.158 868.947 195.158L877.789 195.158C878.603 195.158 879.263 195.818 879.263 196.632Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M821.789 196.632C821.789 197.445 821.13 198.105 820.316 198.105L811.474 198.105C810.66 198.105 810 197.445 810 196.632C810 195.818 810.66 195.158 811.474 195.158L820.316 195.158C821.13 195.158 821.789 195.818 821.789 196.632Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M862.316 148.737C863.13 148.737 863.789 149.397 863.789 150.211L863.789 159.053C863.789 159.867 863.13 160.526 862.316 160.526C861.502 160.526 860.842 159.867 860.842 159.053L860.842 150.211C860.842 149.397 861.502 148.737 862.316 148.737Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M862.316 209.895C863.13 209.895 863.789 210.555 863.789 211.368L863.789 220.211C863.789 221.024 863.13 221.684 862.316 221.684C861.502 221.684 860.842 221.024 860.842 220.211L860.842 211.368C860.842 210.555 861.502 209.895 862.316 209.895Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M879.263 202.526C879.263 203.34 878.603 204 877.789 204L868.947 204C868.133 204 867.474 203.34 867.474 202.526C867.474 201.712 868.133 201.053 868.947 201.053L877.789 201.053C878.603 201.053 879.263 201.712 879.263 202.526Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M821.789 202.526C821.789 203.34 821.13 204 820.316 204L811.474 204C810.66 204 810 203.34 810 202.526C810 201.712 810.66 201.053 811.474 201.053L820.316 201.053C821.13 201.053 821.789 201.712 821.789 202.526Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M833.579 116.316C834.8 116.316 835.789 115.326 835.789 114.105C835.789 112.884 834.8 111.895 833.579 111.895C832.358 111.895 831.368 112.884 831.368 114.105C831.368 115.326 832.358 116.316 833.579 116.316ZM833.579 117.789C835.614 117.789 837.263 116.14 837.263 114.105C837.263 112.071 835.614 110.421 833.579 110.421C831.544 110.421 829.895 112.071 829.895 114.105C829.895 116.14 831.544 117.789 833.579 117.789Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M787.684 145.895C788.905 145.895 789.895 144.905 789.895 143.684C789.895 142.463 788.905 141.474 787.684 141.474C786.463 141.474 785.474 142.463 785.474 143.684C785.474 144.905 786.463 145.895 787.684 145.895ZM787.684 147.368C789.719 147.368 791.368 145.719 791.368 143.684C791.368 141.649 789.719 140 787.684 140C785.649 140 784 141.649 784 143.684C784 145.719 785.649 147.368 787.684 147.368Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M787.684 167.895C788.905 167.895 789.895 166.905 789.895 165.684C789.895 164.463 788.905 163.474 787.684 163.474C786.463 163.474 785.474 164.463 785.474 165.684C785.474 166.905 786.463 167.895 787.684 167.895ZM787.684 169.368C789.719 169.368 791.368 167.719 791.368 165.684C791.368 163.649 789.719 162 787.684 162C785.649 162 784 163.649 784 165.684C784 167.719 785.649 169.368 787.684 169.368Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M794.684 195.895C795.905 195.895 796.895 194.905 796.895 193.684C796.895 192.463 795.905 191.474 794.684 191.474C793.463 191.474 792.474 192.463 792.474 193.684C792.474 194.905 793.463 195.895 794.684 195.895ZM794.684 197.368C796.719 197.368 798.368 195.719 798.368 193.684C798.368 191.649 796.719 190 794.684 190C792.649 190 791 191.649 791 193.684C791 195.719 792.649 197.368 794.684 197.368Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M801.684 204.895C802.905 204.895 803.895 203.905 803.895 202.684C803.895 201.463 802.905 200.474 801.684 200.474C800.463 200.474 799.474 201.463 799.474 202.684C799.474 203.905 800.463 204.895 801.684 204.895ZM801.684 206.368C803.719 206.368 805.368 204.719 805.368 202.684C805.368 200.649 803.719 199 801.684 199C799.649 199 798 200.649 798 202.684C798 204.719 799.649 206.368 801.684 206.368Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M798.684 178.895C799.905 178.895 800.895 177.905 800.895 176.684C800.895 175.463 799.905 174.474 798.684 174.474C797.463 174.474 796.474 175.463 796.474 176.684C796.474 177.905 797.463 178.895 798.684 178.895ZM798.684 180.368C800.719 180.368 802.368 178.719 802.368 176.684C802.368 174.649 800.719 173 798.684 173C796.649 173 795 174.649 795 176.684C795 178.719 796.649 180.368 798.684 180.368Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M845.368 125.158C846.589 125.158 847.579 124.168 847.579 122.947C847.579 121.727 846.589 120.737 845.368 120.737C844.148 120.737 843.158 121.727 843.158 122.947C843.158 124.168 844.148 125.158 845.368 125.158ZM845.368 126.632C847.403 126.632 849.053 124.982 849.053 122.947C849.053 120.913 847.403 119.263 845.368 119.263C843.334 119.263 841.684 120.913 841.684 122.947C841.684 124.982 843.334 126.632 845.368 126.632Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M854.211 116.316C855.431 116.316 856.421 115.326 856.421 114.105C856.421 112.884 855.431 111.895 854.211 111.895C852.99 111.895 852 112.884 852 114.105C852 115.326 852.99 116.316 854.211 116.316ZM854.211 117.789C856.245 117.789 857.895 116.14 857.895 114.105C857.895 112.071 856.245 110.421 854.211 110.421C852.176 110.421 850.526 112.071 850.526 114.105C850.526 116.14 852.176 117.789 854.211 117.789Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M875.579 128.842C876.8 128.842 877.789 127.852 877.789 126.632C877.789 125.411 876.8 124.421 875.579 124.421C874.358 124.421 873.368 125.411 873.368 126.632C873.368 127.852 874.358 128.842 875.579 128.842ZM875.579 130.316C877.614 130.316 879.263 128.666 879.263 126.632C879.263 124.597 877.614 122.947 875.579 122.947C873.544 122.947 871.895 124.597 871.895 126.632C871.895 128.666 873.544 130.316 875.579 130.316Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M894.684 168.895C895.905 168.895 896.895 167.905 896.895 166.684C896.895 165.463 895.905 164.474 894.684 164.474C893.463 164.474 892.474 165.463 892.474 166.684C892.474 167.905 893.463 168.895 894.684 168.895ZM894.684 170.368C896.719 170.368 898.368 168.719 898.368 166.684C898.368 164.649 896.719 163 894.684 163C892.649 163 891 164.649 891 166.684C891 168.719 892.649 170.368 894.684 170.368Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M819.684 242.895C820.905 242.895 821.895 241.905 821.895 240.684C821.895 239.463 820.905 238.474 819.684 238.474C818.463 238.474 817.474 239.463 817.474 240.684C817.474 241.905 818.463 242.895 819.684 242.895ZM819.684 244.368C821.719 244.368 823.368 242.719 823.368 240.684C823.368 238.649 821.719 237 819.684 237C817.649 237 816 238.649 816 240.684C816 242.719 817.649 244.368 819.684 244.368Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M829.684 259.895C830.905 259.895 831.895 258.905 831.895 257.684C831.895 256.463 830.905 255.474 829.684 255.474C828.463 255.474 827.474 256.463 827.474 257.684C827.474 258.905 828.463 259.895 829.684 259.895ZM829.684 261.368C831.719 261.368 833.368 259.719 833.368 257.684C833.368 255.649 831.719 254 829.684 254C827.649 254 826 255.649 826 257.684C826 259.719 827.649 261.368 829.684 261.368Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M844.684 269.895C845.905 269.895 846.895 268.905 846.895 267.684C846.895 266.463 845.905 265.474 844.684 265.474C843.463 265.474 842.474 266.463 842.474 267.684C842.474 268.905 843.463 269.895 844.684 269.895ZM844.684 271.368C846.719 271.368 848.368 269.719 848.368 267.684C848.368 265.649 846.719 264 844.684 264C842.649 264 841 265.649 841 267.684C841 269.719 842.649 271.368 844.684 271.368Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M856.684 246.895C857.905 246.895 858.895 245.905 858.895 244.684C858.895 243.463 857.905 242.474 856.684 242.474C855.463 242.474 854.474 243.463 854.474 244.684C854.474 245.905 855.463 246.895 856.684 246.895ZM856.684 248.368C858.719 248.368 860.368 246.719 860.368 244.684C860.368 242.649 858.719 241 856.684 241C854.649 241 853 242.649 853 244.684C853 246.719 854.649 248.368 856.684 248.368Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M868.684 233.895C869.905 233.895 870.895 232.905 870.895 231.684C870.895 230.463 869.905 229.474 868.684 229.474C867.463 229.474 866.474 230.463 866.474 231.684C866.474 232.905 867.463 233.895 868.684 233.895ZM868.684 235.368C870.719 235.368 872.368 233.719 872.368 231.684C872.368 229.649 870.719 228 868.684 228C866.649 228 865 229.649 865 231.684C865 233.719 866.649 235.368 868.684 235.368Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M906.684 178.895C907.905 178.895 908.895 177.905 908.895 176.684C908.895 175.463 907.905 174.474 906.684 174.474C905.463 174.474 904.474 175.463 904.474 176.684C904.474 177.905 905.463 178.895 906.684 178.895ZM906.684 180.368C908.719 180.368 910.368 178.719 910.368 176.684C910.368 174.649 908.719 173 906.684 173C904.649 173 903 174.649 903 176.684C903 178.719 904.649 180.368 906.684 180.368Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M906.684 188.895C907.905 188.895 908.895 187.905 908.895 186.684C908.895 185.463 907.905 184.474 906.684 184.474C905.463 184.474 904.474 185.463 904.474 186.684C904.474 187.905 905.463 188.895 906.684 188.895ZM906.684 190.368C908.719 190.368 910.368 188.719 910.368 186.684C910.368 184.649 908.719 183 906.684 183C904.649 183 903 184.649 903 186.684C903 188.719 904.649 190.368 906.684 190.368Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M894.684 204.895C895.905 204.895 896.895 203.905 896.895 202.684C896.895 201.463 895.905 200.474 894.684 200.474C893.463 200.474 892.474 201.463 892.474 202.684C892.474 203.905 893.463 204.895 894.684 204.895ZM894.684 206.368C896.719 206.368 898.368 204.719 898.368 202.684C898.368 200.649 896.719 199 894.684 199C892.649 199 891 200.649 891 202.684C891 204.719 892.649 206.368 894.684 206.368Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M827.648 135.557L827.648 149.049L826.175 149.049L826.175 135.557C826.175 135.063 826.34 134.583 826.645 134.194L832.317 126.946C832.419 126.816 832.474 126.657 832.474 126.492L832.474 117.421L833.947 117.421L833.947 126.492C833.947 126.986 833.782 127.465 833.478 127.854L827.805 135.103C827.704 135.232 827.648 135.392 827.648 135.557Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M820.737 230.829L820.737 238L819.263 238L819.263 230.829C819.263 230.083 819.639 229.388 820.263 228.979L825.93 225.271C826.138 225.135 826.263 224.903 826.263 224.655L826.263 220L827.737 220L827.737 224.655C827.737 225.401 827.361 226.096 826.737 226.504L821.07 230.213C820.862 230.349 820.737 230.58 820.737 230.829Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M868.046 226.502L868.046 228.937L869.519 228.937L869.519 226.502C869.519 225.519 868.87 224.654 867.926 224.379L863.563 223.11C863.248 223.019 863.032 222.73 863.032 222.403L863.032 221.086L861.558 221.086L861.558 222.403C861.558 223.386 862.207 224.251 863.151 224.525L867.514 225.794C867.829 225.886 868.046 226.174 868.046 226.502Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M830.737 239.749L830.737 255L829.263 255L829.263 239.749C829.263 239.509 829.302 239.27 829.379 239.043L832.225 230.594C832.25 230.518 832.263 230.439 832.263 230.359L832.263 220L833.737 220L833.737 230.359C833.737 230.599 833.698 230.837 833.621 231.064L830.775 239.513C830.75 239.589 830.737 239.669 830.737 239.749Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M845.737 245.866L845.737 265L844.263 265L844.263 245.866C844.263 245.528 844.341 245.194 844.49 244.891L849.688 234.309C849.737 234.208 849.763 234.096 849.763 233.984L849.763 221L851.237 221L851.237 233.984C851.237 234.322 851.159 234.655 851.01 234.958L845.812 245.541C845.763 245.642 845.737 245.753 845.737 245.866Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M839.474 140.214L839.474 150.211L838 150.211L838 140.214C838 139.616 838.242 139.043 838.672 138.627L844.408 133.067C844.551 132.928 844.632 132.737 844.632 132.538L844.632 125.895L846.105 125.895L846.105 132.538C846.105 133.136 845.863 133.708 845.433 134.125L839.698 139.685C839.554 139.823 839.474 140.014 839.474 140.214Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M855.684 135.5L855.684 149.474L857.158 149.474L857.158 135.5C857.158 135.314 857.134 135.128 857.088 134.948L854.971 126.739C854.955 126.679 854.947 126.617 854.947 126.555L854.947 117.053L853.474 117.053L853.474 126.555C853.474 126.741 853.497 126.926 853.544 127.107L855.661 135.316C855.676 135.376 855.684 135.438 855.684 135.5Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M863.053 141.785L863.053 149.474L861.579 149.474L861.579 141.785C861.579 140.879 862.132 140.064 862.975 139.73L874.377 135.209C874.658 135.097 874.842 134.826 874.842 134.524L874.842 129.579L876.316 129.579L876.316 134.524C876.316 135.43 875.763 136.244 874.92 136.578L863.518 141.1C863.237 141.211 863.053 141.483 863.053 141.785Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M883.065 191.406L878.05 191.406V189.933L883.065 189.933C884.044 189.933 884.906 190.577 885.184 191.516L888.21 201.736C888.303 202.048 888.59 202.263 888.917 202.263L892 202.263V203.737L888.917 203.737C887.938 203.737 887.075 203.093 886.797 202.154L883.771 191.934C883.678 191.621 883.391 191.406 883.065 191.406Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M888.631 185.737L877 185.737V184.263L888.631 184.263C888.835 184.263 889.037 184.291 889.233 184.347L895.906 186.235C895.971 186.254 896.039 186.263 896.107 186.263L904 186.263V187.737L896.107 187.737C895.903 187.737 895.7 187.709 895.505 187.653L888.832 185.765C888.766 185.746 888.699 185.737 888.631 185.737Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M888.631 178.263L877 178.263V179.737L888.631 179.737C888.835 179.737 889.037 179.709 889.233 179.653L895.906 177.765C895.971 177.746 896.039 177.737 896.107 177.737L904 177.737V176.263L896.107 176.263C895.903 176.263 895.7 176.291 895.505 176.347L888.832 178.235C888.766 178.254 888.699 178.263 888.631 178.263Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M798.591 144.737L791 144.737V143.263L798.591 143.263C799.623 143.263 800.518 143.977 800.746 144.983L805.459 165.69C805.535 166.025 805.834 166.263 806.177 166.263L811 166.263L811 167.737L806.177 167.737C805.146 167.737 804.251 167.023 804.022 166.017L799.31 145.31C799.233 144.975 798.935 144.737 798.591 144.737Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M799.029 166.737L791 166.737V165.263L799.029 165.263C799.726 165.263 800.382 165.592 800.799 166.15L805.15 171.968C805.289 172.154 805.507 172.263 805.74 172.263L811 172.263V173.737L805.74 173.737C805.043 173.737 804.387 173.408 803.969 172.85L799.619 167.032C799.48 166.846 799.261 166.737 799.029 166.737Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M803.573 193.263L798 193.263V194.737L803.573 194.737C804.118 194.737 804.643 194.536 805.049 194.172L807.873 191.641C808.008 191.52 808.183 191.453 808.365 191.453L812 191.453V189.979L808.365 189.979C807.82 189.979 807.295 190.18 806.889 190.543L804.065 193.075C803.93 193.196 803.755 193.263 803.573 193.263Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M805.404 177.737L802 177.737V176.263L805.404 176.263C805.928 176.263 806.435 176.449 806.835 176.789L808.365 178.088C808.498 178.201 808.667 178.263 808.842 178.263L811 178.263V179.737L808.842 179.737C808.318 179.737 807.811 179.551 807.411 179.211L805.881 177.912C805.748 177.799 805.579 177.737 805.404 177.737Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M891.5 166.263L876 166.263V167.737L891.5 167.737V166.263Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M811 201.763L804.5 201.763V203.237L811 203.237V201.763Z" fill="#B4965C"/>
<path className="circuit"fill-rule="evenodd" clip-rule="evenodd" d="M857.237 221L857.237 242L855.763 242L855.763 221L857.237 221Z" fill="#B4965C"/>
</g>
{/* laser landing */}
<g className="laser">
<path fill-rule="evenodd" clip-rule="evenodd" d="M244 185C244 184.448 244.448 184 245 184L835 184C835.552 184 836 184.448 836 185C836 185.552 835.552 186 835 186L245 186C244.448 186 244 185.552 244 185Z" fill="#DB0404"/>

<path d="M846.571 185.286C846.571 187.258 844.972 188.857 843 188.857C841.028 188.857 839.429 187.258 839.429 185.286C839.429 183.313 841.028 181.714 843 181.714C844.972 181.714 846.571 183.313 846.571 185.286Z" fill="#DB0404"/>
<path d="M843 166L844.237 177.786H841.763L843 166Z" fill="#DB0404"/>
<path d="M829.01 172.01L838.219 179.469L836.469 181.219L829.01 172.01Z" fill="#DB0404"/>
<path d="M829.01 198.836L836.469 189.628L838.219 191.377L829.01 198.836Z" fill="#DB0404"/>
<path d="M843 205.286L844.237 193.5H841.763L843 205.286Z" fill="#DB0404"/>
<path d="M863 185.286L851.214 184.049L851.214 186.523L863 185.286Z" fill="#DB0404"/>
<path d="M857.265 172.01L848.056 179.469L849.806 181.219L857.265 172.01Z" fill="#DB0404"/>
<path d="M857.265 199.55L849.806 190.342L848.056 192.091L857.265 199.55Z" fill="#DB0404"/>
</g>
{/* end laser landing */}

{/* text */}
<path d="M180.68 315C180.44 313.213 179.947 311.787 179.2 310.72C178.48 309.653 177.547 309.12 176.4 309.12C174.773 309.12 173.48 309.893 172.52 311.44C171.56 312.96 171.08 315.32 171.08 318.52C171.08 322.387 171.827 325.213 173.32 327C174.813 328.787 176.747 329.68 179.12 329.68C180.267 329.68 181.28 329.467 182.16 329.04C183.04 328.613 183.667 328.107 184.04 327.52C184.36 327.76 184.627 328.12 184.84 328.6C185.053 329.08 185.16 329.52 185.16 329.92C185.16 330.587 184.813 331.28 184.12 332C183.453 332.72 182.48 333.333 181.2 333.84C179.92 334.32 178.44 334.56 176.76 334.56C174.413 334.56 172.267 334.013 170.32 332.92C168.4 331.827 166.867 330.173 165.72 327.96C164.573 325.747 164 323.067 164 319.92C164 316.853 164.52 314.253 165.56 312.12C166.6 309.96 168.053 308.347 169.92 307.28C171.813 306.187 174.013 305.64 176.52 305.64C177.107 305.64 177.773 305.72 178.52 305.88C179.267 306.04 179.92 306.253 180.48 306.52C180.88 306.333 181.467 306.147 182.24 305.96C183.013 305.773 183.613 305.667 184.04 305.64C184.813 307.533 185.2 310.653 185.2 315H180.68Z" fill="#4E4E4E"/>
<path d="M195.053 330.56C195.053 331.733 194.679 332.693 193.933 333.44C193.186 334.16 192.213 334.52 191.013 334.52C189.786 334.52 188.799 334.16 188.052 333.44C187.306 332.693 186.933 331.733 186.933 330.56C186.933 329.36 187.306 328.4 188.052 327.68C188.799 326.96 189.786 326.6 191.013 326.6C192.213 326.6 193.186 326.96 193.933 327.68C194.679 328.4 195.053 329.36 195.053 330.56Z" fill="#4E4E4E"/>
<path d="M220.723 306.08C221.149 306.747 221.362 307.587 221.362 308.6C220.429 308.6 219.696 308.747 219.162 309.04C218.629 309.307 218.243 309.787 218.003 310.48L211.202 330.44C210.722 331.827 210.162 332.813 209.522 333.4C208.909 333.987 208.016 334.28 206.842 334.28H205.603L196.283 310.48C196.016 309.787 195.629 309.307 195.123 309.04C194.616 308.747 193.896 308.6 192.962 308.6C192.962 307.64 193.176 306.8 193.603 306.08H205.443C205.923 306.933 206.163 307.773 206.163 308.6C205.416 308.6 204.829 308.76 204.403 309.08C204.003 309.4 203.803 309.84 203.803 310.4C203.803 310.56 203.882 310.88 204.042 311.36L207.403 322.2L208.603 326.6L209.803 322.28L213.003 311.24C213.109 310.813 213.163 310.533 213.163 310.4C213.163 309.84 212.963 309.4 212.562 309.08C212.163 308.76 211.576 308.6 210.803 308.6C210.803 308.067 210.843 307.613 210.923 307.24C211.029 306.84 211.203 306.453 211.443 306.08H220.723Z" fill="#4E4E4E"/>
<path d="M227.396 330.56C227.396 331.733 227.023 332.693 226.276 333.44C225.53 334.16 224.556 334.52 223.356 334.52C222.13 334.52 221.143 334.16 220.396 333.44C219.65 332.693 219.276 331.733 219.276 330.56C219.276 329.36 219.65 328.4 220.396 327.68C221.143 326.96 222.13 326.6 223.356 326.6C224.556 326.6 225.53 326.96 226.276 327.68C227.023 328.4 227.396 329.36 227.396 330.56Z" fill="#4E4E4E"/>
<path d="M780.24 24.8C784.347 24.8 787.28 25.5467 789.04 27.04C790.8 28.5333 791.68 30.8 791.68 33.84C791.68 35.6267 791.24 37.2267 790.36 38.64C789.48 40.0267 788.147 41.12 786.36 41.92C784.573 42.72 782.387 43.12 779.8 43.12H778.12V46.64C778.12 47.7067 778.24 48.5067 778.48 49.04C778.72 49.5733 779.133 49.9467 779.72 50.16C780.333 50.3467 781.253 50.44 782.48 50.44C782.48 50.92 782.4 51.3867 782.24 51.84C782.08 52.2933 781.867 52.68 781.6 53H769.96C769.667 52.68 769.44 52.2933 769.28 51.84C769.12 51.3867 769.04 50.92 769.04 50.44C769.947 50.44 770.587 50.2933 770.96 50C771.36 49.68 771.56 49.16 771.56 48.44V29.64C771.56 28.8933 771.36 28.3733 770.96 28.08C770.587 27.76 769.947 27.6 769.04 27.6C769.04 26.6933 769.333 25.8533 769.92 25.08C771.707 25.08 774.133 25.0133 777.2 24.88C778.747 24.8267 779.76 24.8 780.24 24.8ZM784.72 33.72C784.72 32.0133 784.32 30.6267 783.52 29.56C782.747 28.4933 781.44 27.96 779.6 27.96C779.013 27.96 778.52 28.0133 778.12 28.12V39.84C778.36 39.8933 778.707 39.92 779.16 39.92C781.373 39.92 782.853 39.3333 783.6 38.16C784.347 36.9867 784.72 35.5067 784.72 33.72Z" fill="#4E4E4E"/>
<path d="M802.782 53.52C799.849 53.52 797.502 52.6 795.742 50.76C793.982 48.8933 793.102 46.24 793.102 42.8C793.102 40.3467 793.516 38.2533 794.342 36.52C795.196 34.7867 796.382 33.48 797.902 32.6C799.422 31.72 801.182 31.28 803.182 31.28C805.102 31.28 806.782 31.7067 808.222 32.56C809.689 33.3867 810.822 34.6133 811.622 36.24C812.449 37.8667 812.862 39.84 812.862 42.16C812.862 44.5867 812.436 46.6533 811.582 48.36C810.756 50.0667 809.582 51.36 808.062 52.24C806.542 53.0933 804.782 53.52 802.782 53.52ZM803.102 50.64C804.036 50.64 804.729 50.1067 805.182 49.04C805.636 47.9733 805.862 46 805.862 43.12C805.862 40.6933 805.742 38.8267 805.502 37.52C805.262 36.2133 804.929 35.32 804.502 34.84C804.076 34.36 803.529 34.12 802.862 34.12C801.929 34.12 801.222 34.6533 800.742 35.72C800.289 36.7867 800.062 38.7867 800.062 41.72C800.062 44.12 800.182 45.9733 800.422 47.28C800.662 48.56 800.996 49.44 801.422 49.92C801.876 50.4 802.436 50.64 803.102 50.64Z" fill="#4E4E4E"/>
<path d="M828.618 31.28C829.818 31.28 830.698 31.5867 831.258 32.2C831.818 32.8133 832.098 33.6533 832.098 34.72C832.098 35.8667 831.792 36.8 831.178 37.52C830.592 38.24 829.792 38.6 828.778 38.6C827.898 38.6 827.178 38.3467 826.618 37.84C826.085 37.3333 825.818 36.7333 825.818 36.04C825.818 35.6133 825.858 35.28 825.938 35.04C824.978 35.28 824.165 35.6933 823.498 36.28V48.28C823.498 48.9467 823.592 49.4533 823.778 49.8C823.965 50.1467 824.325 50.4133 824.858 50.6C825.418 50.76 826.245 50.84 827.338 50.84C827.338 51.32 827.285 51.7333 827.178 52.08C827.072 52.4 826.872 52.7067 826.578 53H815.898C815.285 52.3867 814.978 51.6667 814.978 50.84C815.752 50.84 816.298 50.68 816.618 50.36C816.965 50.0133 817.138 49.44 817.138 48.64V37.08C817.138 36.36 816.938 35.8533 816.538 35.56C816.165 35.2667 815.485 35.12 814.498 35.12V33.32C815.378 32.8133 816.538 32.3733 817.978 32C819.445 31.6 820.578 31.4 821.378 31.4C822.018 31.4 822.472 31.6267 822.738 32.08C823.005 32.5333 823.138 33.44 823.138 34.8C823.618 33.8933 824.378 33.08 825.418 32.36C826.458 31.64 827.525 31.28 828.618 31.28Z" fill="#4E4E4E"/>
<path d="M841.146 35.36V46.64C841.146 47.44 841.306 48 841.626 48.32C841.946 48.6133 842.4 48.76 842.986 48.76C843.493 48.76 844.026 48.6267 844.586 48.36C845.146 48.0933 845.586 47.76 845.906 47.36C846.173 47.5733 846.36 47.8933 846.466 48.32C846.6 48.7467 846.666 49.1333 846.666 49.48C846.666 50.0933 846.453 50.6933 846.026 51.28C845.6 51.8667 844.96 52.3467 844.106 52.72C843.253 53.0933 842.226 53.28 841.026 53.28C839.16 53.28 837.653 52.7467 836.506 51.68C835.386 50.6133 834.826 48.84 834.826 46.36V35.36H832.306V32.92C833 32.8133 833.666 32.3733 834.306 31.6C834.973 30.8 835.533 29.8133 835.986 28.64C836.466 27.44 836.773 26.2533 836.906 25.08C837.386 25 837.826 24.96 838.226 24.96C839.186 24.96 839.893 25.24 840.346 25.8C840.826 26.3333 841.066 26.9333 841.066 27.6V31.8H845.706C845.706 32.6 845.666 33.2933 845.586 33.88C845.506 34.44 845.333 34.9333 845.066 35.36H841.146Z" fill="#4E4E4E"/>
<path d="M857.984 20.12C859.184 20.12 860.224 20.3733 861.104 20.88C861.984 21.3867 862.651 22.0133 863.104 22.76C863.584 23.48 863.824 24.2 863.824 24.92C863.824 25.9067 863.558 26.7333 863.024 27.4C862.491 28.0667 861.798 28.4 860.944 28.4C860.144 28.4 859.438 28.12 858.824 27.56C858.211 26.9733 857.904 26.3067 857.904 25.56C857.904 24.6 858.304 23.8267 859.104 23.24C859.024 23.1067 858.878 23 858.664 22.92C858.478 22.8133 858.304 22.76 858.144 22.76C857.504 22.76 856.931 23.2 856.424 24.08C855.944 24.96 855.704 25.9733 855.704 27.12C855.704 27.8933 855.771 28.64 855.904 29.36C856.064 30.08 856.238 30.6667 856.424 31.12C856.638 31.5733 856.838 31.8 857.024 31.8H860.864C860.864 33.5067 860.638 34.7067 860.184 35.4H856.784V48.28C856.784 48.92 856.878 49.4267 857.064 49.8C857.278 50.1467 857.651 50.4133 858.184 50.6C858.744 50.76 859.571 50.84 860.664 50.84C860.664 51.6667 860.358 52.3867 859.744 53H849.184C848.571 52.3867 848.264 51.6667 848.264 50.84C849.064 50.84 849.624 50.6667 849.944 50.32C850.291 49.9733 850.464 49.4133 850.464 48.64V35.4H848.024V32.92C848.904 32.7067 849.691 32.3467 850.384 31.84C850.171 30.56 850.064 29.4133 850.064 28.4C850.064 25.68 850.771 23.6267 852.184 22.24C853.598 20.8267 855.531 20.12 857.984 20.12Z" fill="#4E4E4E"/>
<path d="M871.728 53.52C868.794 53.52 866.448 52.6 864.688 50.76C862.928 48.8933 862.048 46.24 862.048 42.8C862.048 40.3467 862.461 38.2533 863.288 36.52C864.141 34.7867 865.328 33.48 866.848 32.6C868.368 31.72 870.128 31.28 872.128 31.28C874.048 31.28 875.728 31.7067 877.168 32.56C878.634 33.3867 879.768 34.6133 880.568 36.24C881.394 37.8667 881.808 39.84 881.808 42.16C881.808 44.5867 881.381 46.6533 880.528 48.36C879.701 50.0667 878.528 51.36 877.008 52.24C875.488 53.0933 873.728 53.52 871.728 53.52ZM872.048 50.64C872.981 50.64 873.674 50.1067 874.128 49.04C874.581 47.9733 874.808 46 874.808 43.12C874.808 40.6933 874.688 38.8267 874.448 37.52C874.208 36.2133 873.874 35.32 873.448 34.84C873.021 34.36 872.474 34.12 871.808 34.12C870.874 34.12 870.168 34.6533 869.688 35.72C869.234 36.7867 869.008 38.7867 869.008 41.72C869.008 44.12 869.128 45.9733 869.368 47.28C869.608 48.56 869.941 49.44 870.368 49.92C870.821 50.4 871.381 50.64 872.048 50.64Z" fill="#4E4E4E"/>
<path d="M886.204 27.4C886.204 26.7333 886.004 26.24 885.604 25.92C885.23 25.5733 884.524 25.4 883.484 25.4V23.8C884.444 23.2667 885.657 22.8133 887.124 22.44C888.59 22.0667 889.777 21.88 890.684 21.88C891.324 21.88 891.777 22.0133 892.044 22.28C892.31 22.5467 892.444 23.0133 892.444 23.68V48.64C892.444 49.4667 892.604 50.04 892.924 50.36C893.27 50.68 893.844 50.84 894.644 50.84C894.644 51.5867 894.324 52.3067 893.684 53H884.924C884.31 52.3333 884.004 51.6133 884.004 50.84C884.777 50.84 885.337 50.68 885.684 50.36C886.03 50.0133 886.204 49.44 886.204 48.64V27.4Z" fill="#4E4E4E"/>
<path d="M905.857 24.72C905.857 25.8667 905.497 26.7867 904.777 27.48C904.084 28.1467 903.151 28.48 901.977 28.48C900.831 28.48 899.911 28.1467 899.217 27.48C898.551 26.7867 898.217 25.8667 898.217 24.72C898.217 23.6 898.564 22.6933 899.257 22C899.977 21.3067 900.911 20.96 902.057 20.96C903.204 20.96 904.124 21.3067 904.817 22C905.511 22.6933 905.857 23.6 905.857 24.72ZM905.097 48.68C905.097 49.4533 905.284 50.0133 905.657 50.36C906.057 50.68 906.657 50.84 907.457 50.84C907.457 51.6667 907.151 52.3867 906.537 53H897.497C896.884 52.3867 896.577 51.6667 896.577 50.84C897.377 50.84 897.95 50.68 898.297 50.36C898.67 50.0133 898.857 49.44 898.857 48.64V37.04C898.857 36.2933 898.67 35.76 898.297 35.44C897.95 35.12 897.257 34.96 896.217 34.96V33.2C896.857 32.8 897.937 32.3867 899.457 31.96C901.004 31.5067 902.311 31.28 903.377 31.28C903.991 31.28 904.431 31.4267 904.697 31.72C904.964 31.9867 905.097 32.4533 905.097 33.12V48.68Z" fill="#4E4E4E"/>
<path d="M918.798 53.52C915.865 53.52 913.518 52.6 911.758 50.76C909.998 48.8933 909.118 46.24 909.118 42.8C909.118 40.3467 909.531 38.2533 910.358 36.52C911.211 34.7867 912.398 33.48 913.918 32.6C915.438 31.72 917.198 31.28 919.198 31.28C921.118 31.28 922.798 31.7067 924.238 32.56C925.705 33.3867 926.838 34.6133 927.638 36.24C928.465 37.8667 928.878 39.84 928.878 42.16C928.878 44.5867 928.451 46.6533 927.598 48.36C926.771 50.0667 925.598 51.36 924.078 52.24C922.558 53.0933 920.798 53.52 918.798 53.52ZM919.118 50.64C920.051 50.64 920.745 50.1067 921.198 49.04C921.651 47.9733 921.878 46 921.878 43.12C921.878 40.6933 921.758 38.8267 921.518 37.52C921.278 36.2133 920.945 35.32 920.518 34.84C920.091 34.36 919.545 34.12 918.878 34.12C917.945 34.12 917.238 34.6533 916.758 35.72C916.305 36.7867 916.078 38.7867 916.078 41.72C916.078 44.12 916.198 45.9733 916.438 47.28C916.678 48.56 917.011 49.44 917.438 49.92C917.891 50.4 918.451 50.64 919.118 50.64Z" fill="#4E4E4E"/>


{/* buttons */}
<path d="M9.1264 350.5L52.2393 283H343.261L386.374 350.5H9.1264Z" fill="black" stroke="#4E4E4E" stroke-width="10" fillOpacity={.15} className='button' onClick={e => {
              this.showModal();
         }}/>
<path d="M656.478 5L698.285 72.5H989.246L1031.92 5H656.478Z" fill="black" stroke="#4E4E4E" stroke-width="10"fillOpacity={.15} className='button' onClick={e => {
              this.showModal();
         }}/>

</svg>
            <Modal onClose={this.showModal} show={this.state.show}/>
            </div>
        );
    }
}

export default EntryGraphic;