import React from "react";
import "./Modal.css"
import PropTypes from "prop-types";

export default class Modal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {value: '', buttonClass: 'submit'}

        this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  //this.state.value = current pw field 
  handleChange(event) {
    this.setState({value: event.target.value});
    this.setState({
        buttonClass: 'submit'
      })
  }
  handleSubmit(event) {
    event.preventDefault();

  
    }
    onClose = e => {
        this.props.onClose && this.props.onClose(e);
      };

      handleClick = () => {
        this.setState({
          buttonClass: 'submit highlighter'
        })
      }
    render() {
       

        if(!this.props.show){
            return null;
        }
      return <div className="modal">
          <div className="modalContent">
          <div className="button"
           onClick={this.onClose}  
          >
            X
          </div>
          <form onSubmit={this.handleSubmit}>
              <input type="password" name="password" className="pwInput" placeholder="Password" value={this.state.value} onChange={this.handleChange}></input>
              <input type="submit" value="E" className={this.state.buttonClass} onClick={this.handleClick}/>
              
              </form>
          </div>
      </div>;
    }
  }

  Modal.propTypes = {
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired
  };